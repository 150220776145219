<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-settings' }">{{
            $t("SETTINGS")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingSetting"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <b-form>
          <FormBuilder
            v-if="!firstLoader"
            :form="form"
            :schemaJson="schemaJson"
            :v="$v"
          ></FormBuilder>
        </b-form>
        <SkeletonLoaderMain :visible="firstLoader"></SkeletonLoaderMain>
      </template>
      <template v-slot:foot>
        <div
          role="alert"
          v-bind:class="{ show: errors.length }"
          class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingSetting"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_SETTING,
  UPDATE_SETTING
} from "@/modules/setting/store/setting.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      form: {},
      setting: {},
      firstLoader: true,
      isEdit: true
    };
  },
  components: {
    KTCard,
    FormBuilder,
    SaveButtonDropdown
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Settings", route: { name: "list-settings" } },
      { title: "Edit Setting" }
    ]);
    vm.getSetting();
  },
  computed: {
    ...mapGetters(["isLoadingSetting"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    schemaJson() {
      let data = {
        fields: [
          {
            type: "input",
            inputType: "text",
            id: "setting-name-input",
            groupId: "setting-name-group",
            required: "required",
            feedback_id: "setting-live-feedback",
            divider: false,
            i18n: {
              label: "NAME",
              placeholder: "NAME",
              validation: "VALID_NAME"
            },
            validations: {
              required: true,
              minLength: 2
            },
            translatable: true,
            model: "name"
          },
          {
            type: "input",
            inputType: "text",
            id: "setting-value-input",
            groupId: "setting-value-group",
            required: "required",
            feedback_id: "setting-live-feedback",
            divider: false,
            i18n: {
              label: "VALUE",
              placeholder: "VALUE",
              validation: "VALID_VALUE"
            },
            validations: {
              required: true,
              minLength: 1
            },
            translatable: false,
            model: "value"
          }
        ]
      };

      if (this.setting.settingTypeSlug === "switch") {
        data.fields[1] = {
          type: "switch",
          groupId: "setting-value-group",
          id: "setting-value-input",
          inputType: "checkbox",
          required: "required",
          feedback_id: "setting-live-feedback",
          colSm: "auto",
          divider: false,
          default: false,
          i18n: {
            label: "VALUE",
            placeholder: "VALUE",
            validation: "VALID_VALUE"
          },
          validations: {
            required: true
          },
          model: "value"
        };
      }

      return data;
    }
  },
  validations() {
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );
    return tmpValidationObject;
  },
  methods: {
    getSetting() {
      let vm = this;
      let id = this.$route.params.id;
      this.$store
        .dispatch(FETCH_SETTING, id)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.setting = data.data;
            this.generateFormOutOfSchemaJson(this.schemaJson, vm.setting).then(
              () => {
                vm.prepareChangesCheck();
              }
            );
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-settings" });
          }
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onSave(next) {
      let vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }
      this.$store
        .dispatch(UPDATE_SETTING, {
          id: vm.setting.id,
          payload: this.form
        })
        .then(data => {
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });

          vm.prepareChangesCheck().then(() => {
            // let itemEdit = data.data;
            if (next == "continue") {
              //New
            }
            if (next == "new") {
              //New
              this.$router.push({ name: "add-setting" });
            }
            if (next == "exit") {
              this.$router.push({ name: "list-settings" });
            }
          });
        });
    },
    resetVForm() {
      this.$v.$reset();
    }
  }
};
</script>
